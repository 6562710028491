<template>
  <v-dialog
    v-model="updateAchievementModal"
    width="800px"
  >
    <v-card class="py-5 pb-5">
      <v-card-title>
        <div class="text-center text-h5" style="width: 100%">
          Editar conquista
        </div>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          class="mx-5 mt-5"
          @submit.prevent="updateAchievement()"
        >
          <v-text-field
            v-model="achievement.title"
            :rules="[rules.required]"
            outlined
            required
            label="Nome da conquista *"
          />

          <v-textarea
            v-model="achievement.description"
            :rules="[rules.required]"
            outlined
            required
            label="Descrição da conquista *"
            hint="Ex: Acertou o resultado de 5 partidas na rodada"
            rows="3"
          />

          <v-select
            v-model="achievement.rarity"
            outlined
            label="Raridade da conquista"
            :items="rarities"
            :rules="[rules.required]"
          />

          <image-uploader
            title="Clique aqui para enviar a imagem da conquista"
            subtitle="Tamanho recomendado: 150 por 150 pixels"
            :saving="saving"
            :savedImage="olderImage"
            :errorImage="imageOffIcon"
            :fullWidth="true"
            previewWidth="150px"
            previewHeight="150px"
            @img-uploaded="handleImage"
            @img-removed="handleRemoveImage"
          />
        </v-form>
      </v-card-text>

      <v-card-actions class="mt-5 mx-5">
        <v-row justify="center">
          <v-btn
            class="py-4"
            color="primary"
            type="submit"
            :disabled="saving"
            @click="updateAchievement()"
          >
            <v-progress-circular
              v-if="saving"
              indeterminate
              size="25"
              width="3"
              class="px-6"
              color="white"
            />
            <span
              v-else
              class="px-6"
            >
              Salvar
            </span>
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
const imageOffIcon = require('@/assets/icons/image-off.png')

export default {
  components: {
    ImageUploader: () => import('@/components/ImageUploader.vue')
  },
  props: {
    achievementData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      saving: false,
      valid: true,
      id: null,
      image: null,
      olderImage: null,
      imageOffIcon,
      achievement: {
        title: '',
        description: '',
        rarity: '',
        imageType: undefined
      },
      rarities: [
        'Comum',
        'Raro',
        'Épico',
        'Legendário'
      ],
      rules: {
        required: value => !!value || 'Este campo é obrigatório.'
      }
    }
  },
  computed: {
    ...mapState(['achievements']),
    updateAchievementModal: {
      get () {
        return this.$store.state.updateAchievementModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'updateAchievementModal',
          value: val
        })
      }
    }
  },
  watch: {
    updateAchievementModal (val) {
      if (val) {
        const { _id, title, image, description, rarity } = this.achievementData
        this.id = _id
        this.image = image
        this.olderImage = image

        this.achievement.title = title
        this.achievement.description = description
        this.achievement.rarity = rarity
      }

      if (!val) {
        this.achievement = {
          title: '',
          description: '',
          rarity: '',
          imageType: undefined
        }
        this.id = null
        this.image = null
        this.olderImage = null
      }
    }
  },
  methods: {
    updateAchievement () {
      if (this.$refs.form.validate()) {
        if (this.olderImage || this.achievement.imageType) {
          this.saving = true
          this.$http.put(`/achievements/${this.id}`, this.achievement)
            .then((res) => {
              const { data } = res
              if (data.putUrl) {
                this.uploadImage(data.putUrl, this.image, this.success, this.error)
              } else {
                this.success()
              }
            })
            .catch((err) => {
              this.saving = false
              this.$toast.error(err.response.data.message)
            })
        } else {
          this.$toast.error('É necessário enviar uma imagem da conquista.')
        }
      }
    },
    success () {
      this.saving = false
      this.updateAchievementModal = false
      this.$emit('updated')
      this.$toast.success('Cadastro atualizado com sucesso.')
    },
    error () {
      this.saving = false
      this.updateAchievementModal = false
      this.$toast.error('Algo de errado aconteceu ao salvar a imagem.')
    },
    handleImage (file) {
      this.achievement.imageType = file.type
      this.image = file
    },
    handleRemoveImage () {
      this.achievement.imageType = undefined
      this.image = null
      this.olderImage = null
    }
  }
}
</script>
